var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pages > 1
    ? _c(
        "div",
        {
          directives: [{ name: "click-away", rawName: "v-click-away" }],
          staticClass: "pagination"
        },
        [
          _c(
            "b-btn",
            { attrs: { variant: "icon primary", size: "sm", disabled: "" } },
            [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.activePerRowCount) +
                    " " +
                    _vm._s(_vm.activePerRowCount ? "Rows" : "")
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination-inner" },
            [
              _vm.pages > 3
                ? _c(
                    "b-btn",
                    {
                      attrs: {
                        variant: "icon primary",
                        disabled: _vm.activePage == 1
                      },
                      on: { click: _vm.firstPage }
                    },
                    [_vm._v("\n      First\n    ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pageTrays.prevPageNumbers &&
              _vm.pageTrays.prevPageNumbers.length > 0
                ? _c(
                    "div",
                    { staticClass: "pagination-more" },
                    [
                      _c("transition", { attrs: { name: "fade" } }, [
                        _vm.isMoreOptionPrevOpen
                          ? _c(
                              "div",
                              {
                                staticClass: "pagination__more-option--previous"
                              },
                              _vm._l(_vm.pageTrays.prevPageNumbers, function(
                                prevPage,
                                key
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: key,
                                    on: {
                                      click: function($event) {
                                        return _vm.switchPage(prevPage)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(prevPage) +
                                        "\n          "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "pagination__more-option--btn",
                          on: {
                            click: function($event) {
                              _vm.isMoreOptionPrevOpen = !_vm.isMoreOptionPrevOpen
                            }
                          }
                        },
                        [_c("i"), _vm._v(" "), _c("i"), _vm._v(" "), _c("i")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: {
                    variant: "icon primary",
                    disabled: _vm.activePage == 1
                  },
                  on: { click: _vm.previousPage }
                },
                [
                  _c("svg", { attrs: { width: "24", viewBox: "0 0 24 24" } }, [
                    _c("use", { attrs: { "xlink:href": "#icon-arrow-left" } })
                  ])
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.navigablePageNumber, function(page, key) {
                return _c(
                  "b-btn",
                  {
                    key: key,
                    attrs: {
                      variant:
                        page === _vm.activePage ? "primary" : "icon primary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.switchPage(page)
                      }
                    }
                  },
                  [_vm._v("\n      " + _vm._s(page) + "\n    ")]
                )
              }),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { variant: "icon primary", disabled: _vm.isLastPage },
                  on: { click: _vm.nextPage }
                },
                [
                  _c("svg", { attrs: { width: "24", viewBox: "0 0 24 24" } }, [
                    _c("use", { attrs: { "xlink:href": "#icon-arrow-right" } })
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.pageTrays.nextPageNumbers &&
              _vm.pageTrays.nextPageNumbers.length > 0
                ? _c(
                    "div",
                    { staticClass: "pagination__more-option-container" },
                    [
                      _c("transition", { attrs: { name: "fade" } }, [
                        _vm.isMoreOptionNextOpen
                          ? _c(
                              "div",
                              { staticClass: "pagination__more-option--next" },
                              _vm._l(_vm.pageTrays.nextPageNumbers, function(
                                nextPage,
                                key
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: key,
                                    on: {
                                      click: function($event) {
                                        return _vm.switchPage(nextPage)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(nextPage) +
                                        "\n          "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "pagination__more-option--btn",
                          on: {
                            click: function($event) {
                              _vm.isMoreOptionNextOpen = !_vm.isMoreOptionNextOpen
                            }
                          }
                        },
                        [_c("i"), _vm._v(" "), _c("i"), _vm._v(" "), _c("i")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pages > 3
                ? _c(
                    "b-btn",
                    {
                      attrs: {
                        variant: "icon primary",
                        disabled: _vm.isLastPage
                      },
                      on: { click: _vm.lastPage }
                    },
                    [_vm._v("\n      Last\n    ")]
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "txt-body" }, [
            _c("b", [
              _vm._v(_vm._s(_vm.totalFrom) + "-" + _vm._s(_vm.totalTo))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: " txt-muted" }, [
              _vm._v("of " + _vm._s(_vm.countTotal))
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }