const Domain = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Domain')
const PushNotifications = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/PushNotifications/Index.vue')
const Emails = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Emails/Index')
const EmailsConfiguration = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Emails/tabs/Configuration')
const EmailsTemplates = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Emails/tabs/Templates')
const Payments = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Payments')
const Privacy = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Privacy')
const SEO = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/SEO')
const Tracking = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Tracking')
const Achievements = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Achievements')
const Notifications = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Notifications')
const Comments = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Comments')

export const beforeEditorLeave = (to, from, next, root) => {
  if ( root.hub.hasChanged ) {
    root.$store.commit('v2/hubs/settings/SET_EXIT_ROUTE', to)
    root.$bvModal.show('exit-settings-dialog');
    next(false);
    return;
  }
  next();
}

export default [
  {
    path: 'domain',
    name: 'hubSettingsDomain',
    component: Domain,
  },
  {
    path: 'emails',
    name: 'hubSettingsEmails',
    component: Emails,
    redirect: {
      name: 'hubSettingsEmailsConfiguration',
    },
    children: [
      {
        path: 'configuration',
        name: 'hubSettingsEmailsConfiguration',
        component: EmailsConfiguration,
        props: true
      },
      {
        path: 'templates',
        name: 'hubSettingsEmailsTemplates',
        component: EmailsTemplates,
        props: true
      },
    ]
  },
  {
    path: 'push-notifications',
    name: 'hubSettingsPushNotifications',
    component: PushNotifications,
  },
  {
    path: 'payments',
    name: 'hubSettingsPayments',
    component: Payments,
  },
  {
    path: '',
    name: 'hubSettingsPrivacy',
    component: Privacy,
  },
  {
    path: 'seo',
    name: 'hubSettingsSEO',
    component: SEO,
  },
  {
    path: 'tracking',
    name: 'hubSettingsTracking',
    component: Tracking,
  },
  {
    path: 'pages',
    redirect: {
      name: 'hubSettingsPrivacy',
    }
  },
  {
    path: 'achievements',
    name: 'hubSettingsAchievements',
    component: Achievements,
  },
  {
    path: 'notifications',
    name: 'hubSettingsNotifications',
    component: Notifications,
  },
  {
    path: 'comments',
    name: 'hubSettingsComments',
    component: Comments,
  },
];
