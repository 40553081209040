<template>
  <div class="table-bulk-search">
    <div class="search-form" v-if="searchOpen">
      <b-form-input
        :placeholder="$store.getters['isMobile'] ? 'Search...' : 'Search list by name or email...'"
        required
        type="text"
        v-model="search"
        debounce="500"
        autofocus
      />
      <b-btn variant="icon default" @click="applyFilter" :disabled="disabled">
        <svg width="24" viewBox="0 0 24 24"><use xlink:href="#icon-search"></use></svg>
      </b-btn>
    </div>
    <transition name="fade" mode="out-in">
      <b-btn variant="icon primary ml-auto" :disabled="disabled" v-if="!searchOpen" @click="searchOpen = true">
        <svg width="24" viewBox="0 0 24 24"><use xlink:href="#icon-search"></use></svg>
      </b-btn>
      <b-btn v-else variant="icon primary ml-2 my-auto mr-3" size="sm" :disabled="disabled" @click="clearFilter">
        <svg class="icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-cross"></use></svg>
      </b-btn>
    </transition>

  </div>
</template>

<script>
  import debounce from "lodash/debounce";
  import ax from 'axios'

  export default {
    name: 'AudienceSearch',
    data() {
      return {
        segmentDrawerState: false,
        searchOpen: false,
        axiosSource: null,
      }
    },
    props: {
      disabled: Boolean,
    },
    watch: {
      search(val) {
        if (!val) return
        this.applyFilter()
      }
    },
    computed: {
      search: {
        get() { return this.$store.getters['v2/audience/hasFilter']; },
        set(val) {
          this.$store.commit('v2/audience/SET_FILTER_QUERY_PARAMS', val)
          if(!val) {
            this.$store.commit('v2/audience/UPDATE_LOADING_STATE', true);
            this.applyFilter()
          }
        }
      }
    },
    methods: {
      clearFilter() {
        this.search = null
        this.searchOpen = false
      },
      sync() {
        this.$store.commit('v2/audience/SET_PAGE', 1)
        this.$emit('sync')
      },
      async applyFilter() {
        this.$store.commit('v2/audience/SET_PAGE', 1)

        if (this.axiosSource) this.axiosSource.cancel()
        this.axiosSource = ax?.CancelToken?.source()

        await this.$store.dispatch('v2/audience/getAudiences', { cancelToken: this.axiosSource.token })
        this.$store.dispatch('v2/audience/getHubs')
      },
      exit() {
        this.$router.push({name: 'segments'})
      },
      debouncer: debounce((cb, args) => cb(args), 1000),
    },
  }
</script>
