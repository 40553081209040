<template>
  <b-modal :id="id" :static="static" centered ref="modal" size="sm">
    <template slot="modal-header" slot-scope="{ close }">
      <h5 class="txt-body txt-primary">{{ title }}</h5>
      <b-button variant="icon" size="sm" @click="close()" class="close">
        <svg class="icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-cross"></use></svg>
      </b-button>
    </template>
    <form @submit.prevent="$emit('accept', model)">
      <b-form-input
        class="mb-2"
        size="lg"
        ref="input"
        v-model="model"
        :placeholder="placeholder"
        :state="$v.model.$error || error ? false : null"
        @input="$emit('input', $event)"
     />
      <b-alert variant="error" :show="!!error">
        <svg class="alert-icon icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-error-color"></use></svg>
        <div class="alert-content">
          <span class="txt-body">{{ error }}</span>
        </div>
      </b-alert>
    </form>
    <template slot="modal-footer">
      <b-btn :disabled="processing || $v.model.$error" variant="primary" block @click="$emit('accept', value)">
        <transition name="fade" mode="out-in">
          <svg key="loading" v-if="processing" class="btn-left anim-rotate-reverse" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-loading-circle"></use></svg>
          <svg key="loaded" v-else class="btn-left" width="24" viewBox="0 0 24 24"><use :xlink:href="`#icon-${confirmIcon}`"></use></svg>
        </transition>
        {{ processing ? 'Saving...' : confirmText }}
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';
import eventBus from "@app2/core/eventBus";

export default {
  name: 'RenameDialog',
  computed: {
    model: {
      get() {
        setTimeout(() => {
          if(this.$refs.input) this.$refs.input.focus()
        }, 100)
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        if (this.$v && this.$v.model) this.$v.model.$touch();
      }
    }
  },
  props: {
    title: {},
    id: {},
    value: {},
    processing: {},
    placeholder: {},
    error: {},
    static: {
      type: Boolean,
      default: true
    },
    confirmText: {
      type: String,
      default: 'Done'
    },
    confirmIcon: {
      type: String,
      default: 'tick'
    },
    max: {
      type: Number,
      default: 255
    }
  },
  validations() {
    return {
      model: {
        required,
        maxLength: maxLength(this.max)
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      eventBus.$emit('reset-highlight');
    })
  }
};
</script>
