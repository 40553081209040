<template>
  <div class="auth-page">
    <!--
    <div class="auth-page-sidebar">
      <video src="https://cdn.membership.io/1021153/fab6f4714af4260487e992bb817a5873.mp4" playsinline muted loop autoplay></video>
      <div class="inner">
        <div class="auth-page-logo">
          <svg width="120" height="31" viewBox="0 0 120 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_9091_521158)">
              <path d="M26.7707 11.9759C26.2378 11.0587 25.4741 10.2968 24.5556 9.7662L15.1548 4.36344L9.06585 0.808419C8.14729 0.278718 7.10556 -8.48938e-05 6.04522 1.93903e-08C4.98487 8.49326e-05 3.9432 0.279049 3.02472 0.808897C2.10624 1.33875 1.34327 2.10085 0.812372 3.01871C0.281473 3.93658 0.00131911 4.97794 2.04904e-05 6.03829V23.9538C-0.0017377 24.6286 0.109664 25.2988 0.329589 25.9367C0.62212 26.777 1.09669 27.5423 1.71939 28.1779C2.34208 28.8135 3.09751 29.3037 3.93167 29.6134C4.76583 29.9231 5.65811 30.0447 6.54473 29.9695C7.43134 29.8943 8.29039 29.6241 9.06045 29.1783L24.5772 20.2205C25.9649 19.4203 26.9779 18.1015 27.3934 16.5543C27.8088 15.0071 27.5926 13.3583 26.7923 11.9705L26.7707 11.9759ZM1.59383 6.03289C1.59382 5.25589 1.7984 4.49259 2.18701 3.81976C2.57562 3.14692 3.13455 2.58827 3.80758 2.20001C4.48062 1.81174 5.24402 1.60754 6.02101 1.60795C6.79801 1.60836 7.56121 1.81336 8.23383 2.20233L13.5447 5.2711L6.19158 9.5933C5.55637 9.97138 5.09577 10.5846 4.9096 11.2999C4.72344 12.0153 4.82672 12.7753 5.19706 13.415C5.5674 14.0548 6.17496 14.5228 6.88804 14.7176C7.60111 14.9124 8.36223 14.8183 9.00642 14.4558L12.3291 12.5054C12.9748 12.133 13.7419 12.0319 14.462 12.2244C15.1822 12.4168 15.7966 12.8871 16.1705 13.5319C16.3556 13.8515 16.4759 14.2044 16.5245 14.5705C16.5731 14.9366 16.549 15.3086 16.4536 15.6654C16.3583 16.0222 16.1936 16.3567 15.9688 16.6497C15.7441 16.9428 15.4638 17.1887 15.144 17.3733L1.75592 25.1046C1.65366 24.7294 1.5992 24.3427 1.59383 23.9538V6.03289Z" fill="white"/>
              <path d="M34.5881 18.4518L36.1006 16.6423C36.994 17.3891 38.1177 17.805 39.2821 17.8199C40.0113 17.8199 40.4055 17.566 40.4055 17.1501V17.1177C40.4055 16.7125 40.0815 16.4911 38.7527 16.1832C36.6623 15.7025 35.0473 15.1029 35.0473 13.0989V13.0665C35.0473 11.2462 36.4949 9.92824 38.8283 9.92824C40.2847 9.87321 41.7137 10.3342 42.8633 11.23L41.5021 13.1529C40.7064 12.5602 39.7495 12.2231 38.7581 12.1861C38.0937 12.1861 37.775 12.467 37.775 12.8181V12.8451C37.775 13.2934 38.1099 13.4878 39.4711 13.8011C41.7289 14.2873 43.1279 15.0165 43.1279 16.853V16.9016C43.1279 18.911 41.5453 20.0993 39.1632 20.0993C37.4883 20.1262 35.8613 19.5403 34.5881 18.4518Z" fill="white"/>
              <path d="M46.0825 10.0938H53.9742V12.4056H48.7725V13.891H52.4023V16.0516H48.7725V17.6073H54.039V19.9245H46.0825V10.0938Z" fill="white"/>
              <path d="M59.8726 10.0234H62.4923L66.6731 19.9082H63.7563L63.0379 18.1527H59.2568L58.5547 19.9082H55.6973L59.8726 10.0234ZM62.2547 16.0407L61.1744 13.2481L60.0941 16.0407H62.2547Z" fill="white"/>
              <path d="M68.677 10.0938H73.3169C74.8131 10.0938 75.8502 10.4881 76.5092 11.1741C76.8046 11.4738 77.0345 11.8317 77.1842 12.2251C77.3339 12.6184 77.4002 13.0386 77.3788 13.4589V13.4859C77.4021 14.1369 77.22 14.7787 76.8585 15.3206C76.4969 15.8625 75.9741 16.2769 75.3641 16.5054L77.7029 19.9245H74.5647L72.5877 16.9537H71.394V19.9245H68.6932L68.677 10.0938ZM73.1872 14.8039C74.1109 14.8039 74.6457 14.3555 74.6457 13.6425V13.6101C74.6457 12.8431 74.1055 12.4488 73.1711 12.4488H71.394V14.8039H73.1872Z" fill="white"/>
              <path d="M79.1776 15.0314C79.169 14.3696 79.2909 13.7126 79.5363 13.0979C79.7817 12.4831 80.1457 11.9228 80.6077 11.4488C81.0697 10.9748 81.6205 10.5964 82.2287 10.3353C82.8369 10.0742 83.4906 9.93546 84.1524 9.92701H84.3468C85.1573 9.88955 85.9641 10.0576 86.6922 10.4155C87.4203 10.7734 88.0461 11.3096 88.5114 11.9742L86.4696 13.5568C86.2297 13.2043 85.9077 12.9154 85.5313 12.7149C85.1549 12.5145 84.7355 12.4086 84.309 12.4063C83.6652 12.4274 83.0561 12.7033 82.6156 13.1732C82.175 13.6432 81.939 14.2688 81.9594 14.9126C81.9619 14.9414 81.9619 14.9703 81.9594 14.999V15.0314C81.9594 16.5006 82.9262 17.6512 84.3036 17.6512C84.7414 17.6474 85.1718 17.5373 85.5576 17.3303C85.9434 17.1233 86.2731 16.8256 86.5183 16.4628L88.56 17.9212C87.9347 18.8152 87.0425 19.4884 86.0112 19.8445C84.98 20.2005 83.8625 20.2211 82.8188 19.9034C81.7751 19.5857 80.8587 18.9459 80.2008 18.0756C79.5429 17.2053 79.1772 16.1492 79.156 15.0584L79.1776 15.0314Z" fill="white"/>
              <path d="M90.5208 10.0938H93.2215V13.7668H96.7055V10.0938H99.4062V19.9083H96.7055V16.1759H93.2215V19.9083H90.5208V10.0938Z" fill="white"/>
              <path d="M102.863 10.0938H105.596V19.9083H102.863V10.0938Z" fill="white"/>
              <path d="M108.719 10.0938H116.61V12.4056H111.414V13.891H115.044V16.0516H111.414V17.6073H116.686V19.9245H108.719V10.0938Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_9091_521158">
                <rect width="120" height="30.7692" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <h1 class="txt-title txt-white mb-0"><strong>Create, Manage & Sell Your Content All In One Place</strong></h1>
      </div>
    </div>
    -->
    <div class="auth-page-content">
      <div v-if="user && currentStep" class="onboarding">
        <transition name="fade" appear>

          <div class="onboarding-header" v-if="!finished">
            <!--
            <transition name="fade">
              <div class="head-nav" v-if="!finished">
                <b-button variant="icon" @click="back" v-if="currentStep.id !== 1">
                  <icon-back class="back" />
                </b-button>
              </div>
            </transition>

            <b-button @click="back" :disabled="currentStep.id === 3" class="logout mobile" :class="{'step-back': currentStep.id !== 1}">
              <icon-exit v-if="currentStep.id === 1"/>
              <icon-back class="back" v-else />
              {{ currentStep.id === 1 ? 'Logout' : 'Go Back' }}
              <template v-if="currentStep.id === 1">
                <img v-if="$store.state.auth.user && $store.state.auth.user.photo_url" :src="$store.state.auth.user.photo_url">
                <figure class="avatar" v-else>
                  <icon-user />
                </figure>
              </template>
            </b-button>
            -->
            <div class="onboarding-steps" :class="[`active-${currentStep.id}`, {finished: stepsDone}]">
              <div v-for="step in steps" class="onboarding-steps-item">
                <div class="onboarding-steps-num" :class="{ active: step === currentStep, completed: step.id <= currentStep.id - 1 }">
                  <transition name="fade" mode="out-in">
                    <svg v-if="step.id <= currentStep.id - 1" viewBox="0 0 24 24" class="icon-regular"><use xlink:href="#icon-tick"></use></svg>
                    <span v-else>
                      {{ step.number }}
                    </span>
                  </transition>
                  <span class="txt-body onboarding-steps-label"><b>{{ step.label }}</b></span>
                </div>
              </div>
              <div class="onboarding-steps-progress" :style="progressWidth"></div>
            </div>
            <transition name="fade" mode="out-in">
              <h3 class="txt-title txt-center mb-4 pb-4 mx-auto" v-if="currentStep.id == 1"><b>Please tell us your name</b></h3>
              <h3 class="txt-title txt-center mb-4 pb-4 mx-auto" v-else-if="currentStep.id == 2"><b>What phone number can we reach you at for extra support?</b></h3>
              <h3 class="txt-title txt-center mb-4 pb-4 mx-auto" v-else-if="currentStep.id == 3"><b>{{ this.user.name + ',' + currentStep.title }}</b></h3>
              <h3 class="txt-title txt-center mb-4 pb-4 mx-auto" v-else ><b>{{ this.user.name + ',' + currentStep.title }}</b></h3>
            </transition>
          </div>
        </transition>
        <transition name="fade" mode="out-in">
          <div v-if="!finished" class="inner">
            <transition name="fade" mode="out-in">
              <component  class="onboarding-top onboarding-wrap" :is="currentStep.component" @back="back()" @next="next" :loading="loading" :user="this.user"  @loading="loading = $event" @animation="animation = $event" @finish="finish" :class="{loaded: !animation}">
                <template v-slot:back>
                  <b-button v-if="currentStep.id !== 1" :disabled="currentStepIndex.id == 0" block variant="secondary" @click="back">
                    <svg viewBox="0 0 24 24" class="btn-left"><use xlink:href="#icon-arrow-left"></use></svg>
                    Go back
                  </b-button>
                </template>
                <template v-slot:action>
                  <b-button :disabled="loading" block variant="primary" @click="stepNext">
                    {{ currentStepBtn }}
                    <svg viewBox="0 0 24 24" class="btn-right"><use xlink:href="#icon-arrow-right"></use></svg>
                  </b-button>
                </template>
                <template v-slot:finish>
                  <b-button :disabled="loading" block variant="primary" @click="finish">
                    {{ currentStepBtn }}
                    <svg viewBox="0 0 24 24" class="btn-right"><use xlink:href="#icon-tick"></use></svg>
                  </b-button>
                  <b-button v-if="false" @click="next()">Skip</b-button>
                </template>
              </component>
            </transition>
          </div>

          <finish class="onboarding-finish" v-else/>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
  import steps from '@app2/modules/Onboarding/steps';
  import store from "@app2/store";
  import get from 'lodash/get';
  import Finish from '@app2/modules/Onboarding/Components/Finish';
  import AuthLayout from "@app2/modules/Auth/Components/AuthLayout";
  export default {
    components: {
      AuthLayout,
      Finish
    },
    name: 'onboarding',
    metaInfo() {
      return {
        title: 'Onboarding',
      }
    },
    data() {
      return {
        finished: false,
        stepsDone: false,
        animation: true,
        currentStepIndex: 0,
        loading: false,
        steps
      };
    },
    beforeRouteEnter(to, from, next) {
      if (get(store, 'state.auth.user.onboard_step', 0) >= steps.length) {
        next('/');
      } else {
        next();
      }
    },
    computed: {
      user() {
        return this.$store.getters['auth/GET_USER'];
      },
      currentStep() {
        return this.steps[this.currentStepIndex];
      },
      currentStepBtn() {
        switch (this.currentStep.id) {
          //case 1: return 'Begin';
          case 4: return 'Finish';
          default: return 'Continue';
        }
      },
      progressWidth() {
        var widthItem = this.$store.getters['isTablet'] ? this.$store.getters['isMobileXs'] ? 48 + 16 * 2 : 48 + 24 * 2 :  48 + 56 * 2;
        var widthSubstract =  widthItem * 2 - widthItem * (this.currentStepIndex + 1);
        return {
          'width': `calc(50% - 56px - ${ widthSubstract }px)`
        }
      }
    },
    watch: {
      'user.onboard_step'() {
        this.currentStepIndex = Math.min(this.user.onboard_step, steps.length - 1);
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.currentStepIndex = Math.min(this.user.onboard_step, steps.length - 1);
      })
    },
    methods: {
      stepNext() {
        this.loading = true;
        this.animation = false;
        this.$emit('step-next', () => {
          this.loading = false;
        });
      },
      async finish() {
        this.loading = true;
        const index = Math.min(this.user.onboard_step + 1, this.steps.length - 1);
        this.currentStepIndex = index;
        await this.$store.dispatch('auth/updateOnboarding', {
          onboard_step: index,
          is_onboard: true
        });
        this.loading = false;
        setTimeout(()=>{
           this.finished = true;
        },1200);
        this.stepsDone = true;
        await this.$store.dispatch('auth/fetchUser');
        setTimeout(()=>{
           this.$router.push('/getting-started');
        },4500);
      },
      async next(step) {
        this.currentStepIndex = step;
        this.loading = false;
      },
      async back() {
        const shouldLogout = this.currentStep.id === 1
        this.loading = true;
        this.currentStepIndex = Math.max(this.user.onboard_step - 1, 0);
        await this.$store.dispatch('auth/updateOnboarding', {
          onboard_step: this.currentStepIndex,
          name: this.user.name
        })
        this.loading = false;
        if (shouldLogout) {
          await this.logout();
        }
      },
      async logout() {
        this.$store.dispatch('auth/logout');
      }
    },
    created() {
      if (this.$store.getters['isTablet']) {
        const viewportEl = document.querySelector('head meta[content="width=1200"]');
        viewportEl.setAttribute('content', 'width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0');
      }
    }
  };
</script>
