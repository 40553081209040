import store from "@app2/store";

const isMember = () => {
    const teams = store.getters['auth/GET_TEAMS'];
    const user = store.state.auth.user;

    if (!teams || teams.length < 1 || !user) return true;

    return teams.find(t => t.id === user.current_team_id)?.role === 'member';
};

const hasEntitlement = () => {
    const user = store.state.auth.user;
    return user.chargebee_permissions['team-accounts']?.quota > 0 || user.chargebee_permissions['team-accounts']?.quota === 'Unlimited';
}

export const teamGuard = async (to, from, next) => {
    if (!store.getters['auth/GET_TEAMS'] || store.getters['auth/GET_TEAMS'].length < 1) {
        try {
            await store.dispatch('auth/fetchTeams');
        } catch (error) {
            console.error("Failed to load teams:", error);
            next({ name: 'AccountProfile' });
            return;
        }
    }

    if (!store.state.auth.user) {
        try {
            await store.dispatch('auth/fetchUser');
        } catch (error) {
            console.error("Failed to load user:", error);
            next({ name: 'AccountProfile' });
            return;
        }
    }

    if (isMember()) {
        next({ name: 'AccountProfile' });
        return;
    }

    if (to.name === 'AccountTeam' && !hasEntitlement()) {
        next({ name: 'AccountProfile' });
        return;
    }

    next();
};
