import Base from '@app2/models/Base';
import Hub from "@app2/models/Hub";

export default class Page extends Base {
  static entity = 'App\\Page';

  static apiConfig = {
    baseURL: '/api/internal/v2/hubs/',
    actions: {
      fetch: {
        url: '/',
        dataKey: 'data',
      },
      show(hub, page, config) {
        return this.get(`${hub}/pages/${page}`, config);
      },
      update(hub, page, config) {
        return this.put(`${hub}/pages/${page}`, config, {save: false});
      },
      create(hub, title) {
        return this.post(`${hub}/pages`, {title}, {save: false});
      },
      duplicate(hub, page, title) {
        return this.post(`${hub}/pages/${page}/duplicate`, { title })
      },
      destroy(hub, page, config) {
        return this.delete(`${hub}/pages/${page}`, config)
      },
      getHistory(hub, page, config) {
        return this.get(`${hub}/pages/${page}/revisions`, config);
      },
      setHistory(hub, page, config) {
        return this.post(`${hub}/pages/${page}/revisions`, config);
      },
      setAsHome(hub, page, config) {
        return this.put(`${hub}/pages/${page}/homepage`, config);
      }
    }
  };

  watchFields = [
    'title',
    'type',
    'slug',
    'sections',
    'meta',
    'parent_id',
    'settings',
  ];

  requiredFields = [
    'sections',
    'slug',
  ];

  static fields() {
    return {
      id: this.attr(null),
      auth: this.attr(null),
      hub_id: this.attr(null),
      hub: this.belongsTo(Hub, 'hub_id'),
      hash: this.attr(null),
      slug: this.attr(null),
      isSlugTaken: this.attr(false),
      disabled: this.attr(false),
      title: this.attr(null),
      is_homepage: this.attr(false),
      parent_id: this.attr(null),
      parent: this.attr(null),
      type: this.attr(null),
      sections: this.attr([]),
      settings: this.attr(null),
      ...super.fields(),
    };
  }
  static mutators() {
    return {
      meta(value) {
        if ( !value || Array.isArray(value) ) return {};
        return value;
      },
      settings(value) {
        if ( !value || Array.isArray(value) ) return {};
        return value;
      },
      sections(value) {
        if ( value && Array.isArray(value) && value.length ) {
          value.forEach(s => {
            if ( !s ) return;
            if ( !s.settings || Array.isArray(s.settings)) s.settings = {};
            if (s.blocks && s.blocks.length) {
              s.blocks.forEach(b => {
                if ( !b.settings || Array.isArray(b.settings)) b.settings = {};
              })
            }
          });
        }
        return value;
      },
    };
  }

  get isPrivate() {
    return this.auth;
  }

  get slugError() {
    if ( !this.slug ) return null;
    if (this.isSlugReserved) return 'The URL slug is reserved.';
    if (this.isSlugTaken) return 'The URL slug is taken.';
  }

  get isSlugReserved() {
    return ['register', 'login', 'onboarding', 'account', 'payment', 'directory', 'page', 'playlist', 'file', 'search'].includes(this.slug);
  }
}
