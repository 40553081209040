<template>
  <div class="layout-dashboard">
    <account-settings-sidebar />
    <div class="side-panel-inner">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
    <embed-domains-dialog />
  </div>
</template>

<script>
  import AccountSettingsSidebar from "@app2/modules/AccountSettings/Components/Sidebar";
  import EmbedDomainsDialog from "@app2/modules/AccountSettings/Components/Modals/Domains";

  export default {
    name: 'AccountSettings',
    metaInfo() {
      return {
        titleTemplate: `Membership.io | Account Settings | %s`
      }
    },
    components: {
      EmbedDomainsDialog,
      AccountSettingsSidebar
    },
  }
</script>
