export default {
  methods: {
    onGridSizeChanged(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
      window.onresize = () => {
        this.gridApi.sizeColumnsToFit();
      }

      params.columnApi.setColumnVisible('tags', false)
      params.columnApi.setColumnVisible('hubs', false)
    },
    audienceTypeRenderer(params) {
      switch(params.value) {
        case 'Visitor':
          return `<div class="audience-type audience-type--visitor"><svg class="icon-visitor" xmlns="http://www.w3.org/2000/svg" width="17.529" height="12.563" viewBox="0 0 17.529 12.563"><path d="M8.772 3.103a3.187 3.187 0 103.187 3.187 3.187 3.187 0 00-3.187-3.187zm0 4.874a1.688 1.688 0 111.687-1.687 1.689 1.689 0 01-1.687 1.687z" fill="#939393"/><path d="M16.647 8.773a3.965 3.965 0 00-.009-4.99 10.091 10.091 0 00-15.752 0 3.962 3.962 0 000 5 9.8 9.8 0 00.719.8 10.076 10.076 0 0014.237.05l.007-.006a11.037 11.037 0 00.782-.836c.016 0 .016-.01.016-.018zm-1.851-.2a8.578 8.578 0 01-12.127-.037 8.309 8.309 0 01-.612-.687v-.006a2.463 2.463 0 010-3.111 8.595 8.595 0 0113.417 0 2.47 2.47 0 010 3.115l-.005.012a9.662 9.662 0 01-.663.708z" fill="#939393"/></svg>${params.value}</div>`
          break;
        case 'Person':
          return `<div class="audience-type audience-type--person"><svg class="icon-user" xmlns="http://www.w3.org/2000/svg" width="13.5" height="17.531" viewBox="0 0 13.5 17.531"><path vector-effect="non-scaling-stroke" d="M6.75 7.88A6.751 6.751 0 000 14.63v.846a2.061 2.061 0 002.061 2.06h9.379a2.061 2.061 0 002.06-2.06v-.846a6.751 6.751 0 00-6.75-6.75zm5.25 7.6a.563.563 0 01-.56.56H2.061a.563.563 0 01-.561-.56v-.846a5.25 5.25 0 0110.5 0zM6.75 7.111a3.555 3.555 0 10-3.555-3.555A3.555 3.555 0 006.75 7.111zm0-5.611a2.055 2.055 0 11-2.056 2.056A2.059 2.059 0 016.75 1.5z" fill="#939393"/></svg>${params.value}</div>`
          break;
      }
    },
    userNameRenderer(params) {
      if(params.data.photo_url && !params.data.photo_url.includes('ui-avatars.com/api')) {
        return `<figure class="ag-username">
          <div class="avatar">
            <img src="${params.data.photo_url}">
          </div>
          <figcaption>
            <span class="txt-heading-small txt-dark txt-bold">${params.value}</span>
            <span class="txt-body txt-muted">${params.data.email}</span>
          </figcaption>
        </figure>`
      } else {
        return `<figure class="ag-username">
          <div class="avatar">
            <img src="https://ui-avatars.com/api/?name=${params.value}&rounded=true&size=100&font-size=0.35&background=e6f7d1&color=000000&bold=true" />
          </div>
          <figcaption>
            <span class="txt-heading-small txt-dark txt-bold">${params.value}</span>
            <span class="txt-body txt-muted">${params.data.email}</span>
          </figcaption>
        </figure>`
      }
    },
    seenRenderer(params) {
      return moment(params.value).format('MMMM Do YYYY, h:mm a')
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    navigateToNextCell(params) {
        var previousCell = params.previousCellPosition;
        var suggestedNextCell = params.nextCellPosition;

        var KEY_UP = 38;
        var KEY_DOWN = 40;
        switch (params.key) {
          case KEY_DOWN:
              previousCell = params.previousCellPosition
              // set selected cell on current cell + 1
              this.gridOptions.api.forEachNode((node) => {
                  if (previousCell.rowIndex + 1 === node.rowIndex) {
                      // node.setSelected(true)
                      this.$store.dispatch('v2/audience/getAudienceAnalytics', node.data.hash)
                      this.$store.commit('v2/audience/SET_SELECTED_ROW_DATA', node.data)
                  }
              });
              return suggestedNextCell
          case KEY_UP:
              previousCell = params.previousCellPosition
              // set selected cell on current cell - 1
              this.gridOptions.api.forEachNode((node) => {
                  if (previousCell.rowIndex - 1 === node.rowIndex) {
                      // node.setSelected(true)
                      this.$store.dispatch('v2/audience/getAudienceAnalytics', node.data.hash)
                      this.$store.commit('v2/audience/SET_SELECTED_ROW_DATA', node.data)
                  }
              });
              return suggestedNextCell
        }
    },
    removeAgGridFieldPrefix(colId) {
      return colId.endsWith('_1') ? colId.split('_1')[0] : colId
    },
    sortChanged(event) {
      let column = null
      if(event.api.getSortModel().length != 0) {
        let sortModel = event.api.getSortModel()[0]
        const colId = this.removeAgGridFieldPrefix(sortModel.colId)
        column = `${sortModel.sort == 'asc' ? '' : '-'}${colId}`
      }
      this.$store.dispatch('v2/audience/setSortQueryParams', column)
      this.$store.dispatch('v2/audience/getAudiences')
    },
    onGridReady(params) {
      this.gridApi = params.api
      this.gridColumnApi = this.gridOptions.columnApi;

      this.gridApi.sizeColumnsToFit();
      window.onresize = () => {
          this.gridApi.sizeColumnsToFit();
      }
    },
  }
}
