import Base from '@app2/models/Base';

export default class DiscussionCategory extends Base {
  static primaryKey = 'hash';
  static entity = 'App\\DiscussionCategory';

  static apiConfig = {
    baseURL: `/api/internal/v2/hubs/`,
    actions: {
      fetch(hash, config) {
        return this.get(`${hash}/discussion-category`, {
          dataKey: 'data',
          ...config,
        });
      },
      show(hash, category, config) {
        return this.get(`${hash}/discussion-category/${category}`, config);
      },
      create(hash, params) {
        return this.post(`${hash}/discussion-category`, params);
      },
      destroy(hash, category) {
        return this.delete(`${hash}/discussion-category/${category}`);
      },
      update(hash, category, name) {
        return this.put(`${hash}/discussion-category/${category}`, name);
      },
      reorder(hash, data) {
        return this.put(`${hash}/discussion-category/reorder`, data);
      }
    },
  };

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      hash: this.attr(null),
      slug: this.attr(null),
      discussions_count: this.attr(null),
      is_default: this.attr(null),
      posting_permissions: this.attr([]),
      created_at: this.attr(null),
      deleted_at: this.attr(null),
      updated_at: this.attr(null),
    };
  }
}
