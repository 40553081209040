import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';

import auth from '@app2/modules/Auth/auth.store';
import addMedia from '@app2/modules/Library/Media/addMedia.store';
import canva from '@app2/modules/Library/Media/TranscriptEditor/mixins/canva.store'
import v2 from '@app2/store/2.0';
import filter from '@app2/modules/Audience/filter.store';

import database from '@app2/store/database';
import {mediaQuery} from "@app2/utils/helpers";

Vue.use(Vuex);

VuexORM.use(VuexORMAxios, {
  axios,
  baseURL: '/api/',
});

export default new Vuex.Store({
  state: {
    ongoingRequest: false,
    hasLoadedFirstRoute: false,
    shortcuts: [],
  },
  mutations: {
    setLoading(state, value) {
      state.ongoingRequest = value;
    },
    setLoadedFirstRoute(state, value) {
      state.hasLoadedFirstRoute = value;
    },
    setShortcuts(state, value) {
      state.shortcuts = value;
    },
  },
  actions: {
    async fetchShortcuts({commit}) {
      try {
        const {data} = await axios.get('/api/internal/v2/hubs/shortcuts');
        commit('setShortcuts', data.data);
      } catch (e) {
        console.error(e);
      }
    },
  },
  modules: {
    auth,
    canva,
    v2,
    addMedia,
    filter,
  },
  plugins: [
    VuexORM.install(database),
  ],
  getters: {
    isLaptop: state => mediaQuery({
      maxWidth: 1440
    }),
    isTablet: state => mediaQuery({
      maxWidth: 1200
    }),
    isMobile: state => mediaQuery({
      maxWidth: 991
    }),
    isMobileXs: state => mediaQuery({
      maxWidth: 576
    }),
    isMobile4: state => mediaQuery({
      maxWidth: 420
    }),
    shortcuts: (state, getters, rootState) => {
      let shortcuts = state.shortcuts;
      const fmlShortcut = shortcuts.find(shortcut => shortcut.title?.toLowerCase().includes('fml'));
      if ( fmlShortcut && !rootState?.auth?.user?.is_fml ) {
        shortcuts = shortcuts.filter(shortcut => shortcut.id !== fmlShortcut.id);
      }
      return shortcuts;
    }
  }
});
