var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "audience-slide-in-template",
    {
      attrs: {
        "back-route": { name: "ImportIndex" },
        "back-label": "Back to Import Audience",
        title: "Recent Imports"
      }
    },
    [
      _c("h6", { staticClass: "txt-body txt-muted mb-0" }, [
        _vm._v("Here you can see all your imports to Audience.")
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "TransitionGroup",
        { staticClass: "mb-4", attrs: { tag: "div", name: "fade-list" } },
        [
          _vm._l(_vm.imports, function(data, key) {
            return _c("side-panel-collapse", {
              key: "collapse-" + key,
              attrs: { data: data }
            })
          }),
          _vm._v(" "),
          _vm._l(3, function(elem, index) {
            return _vm.isLoading
              ? _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "skeleton-item side-panel-collapse no-shadow no-events"
                  },
                  [
                    _c("div", { staticClass: "slot-top pt-2" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "130",
                            height: "10",
                            viewBox: "0 0 130 10",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("rect", {
                            attrs: {
                              y: "0.996094",
                              width: "130",
                              height: "10",
                              rx: "2",
                              fill: "#AFC3B5"
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "slot-bottom pb-2" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "50",
                            height: "9",
                            viewBox: "0 0 50 9",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("rect", {
                            attrs: {
                              y: "0.996094",
                              width: "50",
                              height: "8",
                              rx: "2",
                              fill: "#AFC3B5"
                            }
                          })
                        ]
                      )
                    ])
                  ]
                )
              : _vm._e()
          })
        ],
        2
      ),
      _vm._v(" "),
      !_vm.isLoading && !_vm.imports.length
        ? _c(
            "div",
            { staticClass: "side-panel-group-empty" },
            [
              _c("illustration-dog-standing-front", { staticClass: "mb-4" }),
              _vm._v(" "),
              _c("p", { staticClass: "txt-body txt-muted txt-center mb-4" }, [
                _vm._v("You have no recent imports. Upload a CSV file to "),
                _c("br"),
                _vm._v(" start importing your contacts.")
              ]),
              _vm._v(" "),
              _c(
                "b-btn",
                { attrs: { variant: "primary", to: { name: "ImportIndex" } } },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "btn-left",
                      attrs: { viewBox: "0 0 24 24" }
                    },
                    [_c("use", { attrs: { "xlink:href": "#icon-upload" } })]
                  ),
                  _vm._v("\n      Upload CSV\n    ")
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "infinite-loading",
        {
          staticStyle: { "min-height": "50px" },
          attrs: { identifier: _vm.infiniteId, spinner: "waveDots" },
          on: { infinite: _vm.infiniteHandler }
        },
        [
          _c("div", { attrs: { slot: "spinner" }, slot: "spinner" }),
          _vm._v(" "),
          _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }),
          _vm._v(" "),
          _c("div", { attrs: { slot: "no-results" }, slot: "no-results" })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }