import BaseLink from 'prosemirror-to-html-js/src/Marks/Link';

const convertToAbsoluteURL = (url) => {
  if ( !url ) return url;
  // check for mailto: and tel: links
  if (url.startsWith('mailto:') || url.startsWith('tel:')) {
    return url;
  }
  // check for relative links
  if (!url.startsWith('http')) {
    return `https://${url}`;
  }
  return url;
}
export default class Link extends BaseLink {
  tag () {
    return [
      {
        tag: "a",
        attrs: {
          href: convertToAbsoluteURL(this.mark.attrs.href),
          target: this.mark.attrs.target === '_self' ? '_top' : this.mark.attrs.target,
        }
      }
    ]
  }
}
