import Vue from 'vue';
import get from 'lodash/get';
import moment from '@app2/utils/moment';
import store from '@app2/store';
import momentGlobal from 'moment';
import {humanReadableDuration, humanReadableDurationWithMs, prettyBites} from '@app2/utils/helpers';
import currencies from "@app2/utils/currencies.json";
import { richTextJsonToString } from '@app2/utils/helpers.js'

Vue.filter('prettyBytes', (num) => prettyBites(num));

Vue.filter('humanReadableDuration', humanReadableDuration);
Vue.filter('humanReadableDurationWithMs', humanReadableDurationWithMs);

Vue.filter('uppercase', value => value.toUpperCase());
Vue.filter('lowercase', value => value.toLowerCase());
Vue.filter('capitalize', value => {
  if (! value && value !== 0) {
    return '';
  }

  return value.toString().charAt(0).toUpperCase()
    + value.slice(1);
});

/**
 * Get User Avatar
 */
Vue.filter('avatar', avatar => {
  let photo;
  let name = avatar.name;

  if(avatar.photo) {
    photo = avatar.photo;

  } else {
    photo = `https://ui-avatars.com/api/?name=` + name + `&rounded=true&size=35&font-size=0.4&background=e6f7d1&color=000000&bold=true`;
  }

  return photo
});

/**
 * Format the given date.
 */
Vue.filter('date', value => {
  return moment(value).format('MMMM Do, YYYY')
});

/**
 * Format the given date as a timestamp.
 */
Vue.filter('datetime', value => {
  return moment(value).format('MMMM Do, YYYY h:mm A');
});

/**
 * Format the given date as a timestamp.
 */
Vue.filter('datetimeShort', value => {
  return moment(value).format('MMM D h:mmA');
});

/**
 * Format the given date into a relative time.
 */
Vue.filter('relative', value => {
  return moment(value).locale('en-short').fromNow();
});


Vue.filter('formatTime', function (date, format) {
  return moment(date).format(format);
});

Vue.filter('truncate', function (text, length, suffix) {
      if (text.length > length) {
          return text.substring(0, length) + suffix;
      } else {
          return text;
      }
  })

export const twoDigit = (number) => {
  const length = 2
  if (number.toString().length > 2) return number;
  return (new Array(length).join('0')+number).slice(length*-1);
}

Vue.filter('duration', (seconds, noHours) => {
  let totalSeconds = seconds !== 0 ? Math.round(seconds*10)/10 : 0;
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  return `${(noHours && hours === 0 ? '' : `${twoDigit(hours)}:`)}${twoDigit(minutes)}:${twoDigit(Math.floor(totalSeconds % 60))}`
})

Vue.filter('withCommas', x => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})

Vue.filter('durationHumanReadable', value => {
    let [hr, min, sec] = value.split(':')
    if(parseInt(hr) === 0 && parseInt(min) === 0) {
      return `${parseInt(sec)} sec`
    } else {
      return `${parseInt(hr)} hr ${parseInt(min)} min`
    }
})

Vue.filter('formatPrice', price => {
  let formattedPrice = '';
  if ( price.type === 'one_time' ) formattedPrice += 'One time payment of ';
  let currencyObj = currencies[price.currency.toUpperCase()];
  formattedPrice += currencyObj.symbol + (price.amount / 100);
  if ( price.type === 'one_time' ) {
    formattedPrice += '.'
  } else {
    formattedPrice += ' every ';
    if ( price.interval_count === 1 ) {
      formattedPrice += price.interval;
    } else {
      formattedPrice += `${price.interval_count} ${price.interval}s`;
    }
  }
  return formattedPrice;
},)

Vue.filter('formatProductPrice', price => {
  let formattedPrice = '';
  if ( price.type === 'one_time' ) formattedPrice += 'One time payment of ';
  let currencyObj = currencies[price.currency.toUpperCase()];
  formattedPrice += currencyObj.symbol + (price.amount / 100);
  if ( price.type === 'one_time' ) {
    formattedPrice += ''
  } else {
    formattedPrice += ' / ';
    if ( price.interval_count === 1 ) {
      formattedPrice += `${price.interval === 'month' ? 'mo' : 'yr'}`;
    } else {
      formattedPrice += `${price.interval_count} ${price.interval}s`;
    }
  }
  return formattedPrice;
},)

/**
 * Format the given money value.
 *
 * Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L70
 */
export const currencyFilter = (value, decimals = 2, hideIfZeroDecimals = false) => {
  value = parseFloat(value);

  if (! isFinite(value) || (! value && value !== 0)){
    return '';
  }

  var stringified = Math.abs(value).toFixed(decimals);

  if ( hideIfZeroDecimals && stringified.slice(-1 - decimals) === '.00' ) {
    stringified = stringified.slice(0, -1 - decimals);
  }

  var _int = stringified.slice(0, -1 - 2);

  var i = _int.length % 3;

  var head = i > 0
    ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
    : '';

  var _float = stringified.slice(-1 - 2);

  var sign = value < 0 ? '-' : '';

  return sign + window.Spark.currencySymbol + head +
    _int.slice(i).replace(/(\d{3})(?=\d)/g, '$1,') +
    _float;
}
Vue.filter('currency', (value, decimals = 2, hideIfZeroDecimals = false) => {
  return currencyFilter(value, decimals, hideIfZeroDecimals);
});

Vue.filter('richTextJsonToString', value => {
  return richTextJsonToString(value)
})

/**
 * Check if the user has the specified permission to perform the given ability on the provided model.
 * @param {string} ability - The ability to check for.
 * @param {object} model - The model to check against.
 * @param {object} options - An object containing optional viewOnly and existsOnly flags.
 * @param {boolean} options.viewOnly - If true, only check if the user has view access to the model.
 * @param {boolean} options.existsOnly - If true, only check if the model exists.
 * @returns {boolean} - Returns true if the user has the permission to perform the specified ability.
 */
export const hasPermission = (ability, model, { viewOnly = false, existsOnly = false } = {}) => {
  // Extract the permissions object from the model or set it to an empty object if not present.
  const { permissions = {} } = model ?? {};
  
  // Extract the chargebee_permissions object from the user's auth state or set it to an empty object if not present.
  const { chargebee_permissions = {} } = store?.state?.auth?.user ?? {};
  
  // Extract the flags object from the client's auth state or set it to an empty object if not present.
  const { flags = {} } = store?.state?.auth?.client ?? {};
  
  // If the user has the permission to perform the specified ability, return true.
  if (permissions[ability]) {
    return true;
  }
  
  // Check if the user has the specified ability in the chargebee_permissions object.
  const chargebeeAbility = chargebee_permissions[ability];
  if (chargebeeAbility && typeof chargebeeAbility === 'boolean') {
    return true;
  }

  if ( typeof chargebeeAbility === 'object' ) {
    // If viewOnly is true, check if the user has the view quota for the specified ability.
    if ( viewOnly ) {
      return !!chargebee_permissions[ability].quota;
    }

    // If existsOnly is true, check if the user has the used quota for the specified ability.
    if (existsOnly) {
      return !!chargebee_permissions[ability].used;
    }

    // Check if the user has enough quota to perform the specified ability.
    if (chargebee_permissions[ability].quota > chargebee_permissions[ability].used || chargebee_permissions[ability].quota === 'Unlimited') {
      return true;
    }
  }
  
  // Check if the specified ability is set in the flags object.
  if (flags[ability]) {
    return true;
  }
  
  // If none of the above conditions are met, return false.
  return false;
};

Vue.prototype.$canView = (ability, model) => {
  return hasPermission(ability, model, { viewOnly: true });
}

Vue.prototype.$can = (ability, model) => {
  return hasPermission(ability, model);
}

Vue.prototype.$has = (ability, model) => {
  return hasPermission(ability, model, { existsOnly: true });
}

Vue.prototype.$cant = (ability, model) => !Vue.prototype.$can(ability, model);

Vue.prototype.$cantView = (ability, model) => !Vue.prototype.$canView(ability, model);

Vue.prototype.$hasNot = (ability, model) => !Vue.prototype.$has(ability, model);

const isCurrent = (plan, on) => on.current_billing_plan.includes(plan);
const isInSubscriptions = (plan, on) => !!on.subscriptions.filter(sub => sub.stripe_plan.includes(plan) && sub.stripe_status === 'active').length;
const isTrialing = (plan, on) => !!on.subscriptions.filter(sub => sub.stripe_plan.includes(plan) && sub.stripe_status === 'trialing').length;
const hasPaidTrial = (on) => on && !!on.has_paid_trial;


// Vue.prototype.$hasPlan = (plan, on = window.Spark.state.currentTeam) =>
//   (isInSubscriptions(plan, on) && isCurrent(plan, on)) || (isTrialing(plan, on) && hasPaidTrial(on))

Vue.prototype.$hasPlan = (plan) => {
  let currentPlan = store.getters['auth/GET_PLAN'];
  if (currentPlan.item_id === plan && currentPlan.status === 'active') return true;
}

Vue.prototype.$hasTrial = () => {
  let trial = store.getters['auth/GET_TRIAL'];
  return trial.days_left > 0;
}

Vue.prototype.$hasPaidTrial = () => {
  let trial = store.getters['auth/GET_TRIAL'];
  return trial.days_left > 0 && trial.isPaid;
}

