<template>
  <iframe class="ph-no-capture" height="850px" width="100%" frameborder="0" :src="`https://membership.firstpromoter.com/iframe?at=${token}`" style="height: 90vh"></iframe>
</template>

<script>
  import get from 'lodash/get';
  export default {
    name: 'affiliates',
    metaInfo() {
      return {
        title: 'Rewards',
      }
    },
    computed: {
      token() {
        const team = this.$store.getters['auth/GET_TEAM'];
        return team && get(team, 'meta.affiliates.auth_token', false)
      }
    }
  }
</script>
