var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    staticClass: "ph-no-capture",
    staticStyle: { height: "90vh" },
    attrs: {
      height: "850px",
      width: "100%",
      frameborder: "0",
      src: "https://membership.firstpromoter.com/iframe?at=" + _vm.token
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }