import Vue from 'vue';

const namespaced = true;

const state = {
  start: 0, // ms
  end: 0, // ms
  mediaLength: 0, // seconds
  statusTrim: null,
  currentTime: 0, // seconds
  bus: new Vue(),
  media: null,
};

const mutations = {
  SET_START(state, payload) {
    if ( typeof payload === 'string' ) payload = parseFloat(payload);
    if ( payload < 0 ) {
      state.start = 0;
    } else if ( payload >= state.end - 1) {
      state.start = state.end - 1;
    } else {
      state.start = payload;
    }
  },
  SET_END(state, payload) {
    if ( typeof payload === 'string' ) payload = parseFloat(payload);
    if ( payload >= state.mediaLength ) {
      state.end = state.mediaLength;
    } else if ( payload <= state.start + 1) {
      state.end = state.start + 1;
    } else {
      state.end = payload;
    }
  },
  SET_MEDIA_LENGTH(state, payload) {
    if ( typeof payload === 'string' ) payload = parseFloat(payload);
    state.mediaLength = payload;
  },
  SET_STATUS_TRIM(state, payload) {
    state.statusTrim = payload;
  },
  SET_CURRENT_TIME(state, payload) {
    state.currentTime = payload;
  },
  RESET_BUS(state) {
    state.bus = null;
    state.bus = new Vue();
  },
  RESET(state) {
    state.bus = null;
    state.bus = new Vue();
    state.start = 0;
    state.end = 0;
    state.mediaLength = 0;
    state.statusTrim = null;
    state.currentTime = 0;
    state.media = null;
  },
  SET_MEDIA(state, payload) {
    state.media = payload;
  },
};

const actions = {
  setStart({ commit }, payload) {
    commit('SET_START', payload)
  },
  setEnd({ commit }, payload) {
    commit('SET_END', payload)
  },
  setMediaLength({ commit }, payload) {
    commit('SET_MEDIA_LENGTH', payload)
  },
  setStatusTrim({ commit }, payload) {
    commit('SET_STATUS_TRIM', payload)
  },
  setCurrentTime({ commit }, payload) {
    commit('SET_CURRENT_TIME', payload)
  },
  reset({ commit }) {
    commit('RESET')
  }
};

const getters = {
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
};
