<template>
  <div class="form-control-select">
    <h5 v-if="title" class="txt-heading-small" :class="{'mb-3': !hideField}">{{ title }}</h5>
    <label v-if="label" class="form-control-label txt-body mb-1" :class="customClass">{{ label }}</label>
    <span class="form-control-description txt-body mt-0 mb-3" v-if="description">{{ description }}</span>
    <slot name="description"></slot>
    <multiselect
      v-if="!hideField"
      v-model="model"
      :tag-placeholder="tagPlaceholder"
      :closeOnSelect="closeOnSelect"
      ref="multiselect"
      :taggable="taggable"
      :placeholder="placeholder"
      :track-by="trackBy"
      :label="selectLabel"
      :options="options"
      :block-keys="blockKeys"
      :disabled="disabled"
      :multiple="multiple"
      :loading="isSearching"
      :hide-selected="hideSelected"
      :preselect-first="preselectFirst"
      :class="{'is-multiple': multiple, 'multiselect-small': small, 'read-only': readonly, [customSelectClass]: !!customSelectClass}"
      @open="open"
      @close="close"
      @tag="$emit('tag', $event)"
      @select="$emit('select', $event)"
      @search-change="$emit('search-change', $event)"
    >
      <template #beforeList v-if="multiple && model.length && showSelectedItemsList">
        <li
          v-for="item in model"
          :key="'msl-' + item.id"
          class="multiple-selected-list"
          @click="removeItem(item)"
        >
          <i class="icon txt-primary mr-2">
            <svg viewBox="0 0 24 24"><use xlink:href="#icon-tick"></use></svg>
            <svg viewBox="0 0 24 24" class="on-hover"><use xlink:href="#icon-cross"></use></svg>
          </i>
          <slot name="optionIcon" :item="item"></slot>
          {{ item[selectLabel] }}
        </li>
        <hr>
      </template>
      <template slot="caret" slot-scope="{ toggle }">
        <svg @mousedown.prevent.stop="toggle" v-if="!isSearching" viewBox="0 0 24 24" class="icon-regular caret"><use xlink:href="#icon-chevron-down"></use></svg>
        <svg @mousedown.prevent.stop="toggle" v-else class="icon-multiselect-spinner" width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.853516 6C0.853516 3.2 3.05352 1 5.85352 1" stroke="#06211E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
        <svg viewBox="0 0 24 24" v-if="!small && searchable" :class="{visible:searchVisible}" class="icon-regular txt-dark icon-search"><use xlink:href="#icon-search"></use></svg>
      </template>
      <template slot="noResult">
        <span v-show="!fetching">{{ noMatchingString }}</span>
      </template>
      <!-- <span slot="tag"></span> -->
      <template slot="selection"></template>
      <template slot="singleLabel" slot-scope="props">
        <svg v-if="inputIcon" viewBox="0 0 24 24" class="icon-regular mr-2" :class="singleLabelIconClass"><use :xlink:href="`#icon-${inputIcon}`"></use></svg>
        <slot :props="props"><span>{{ props.option[selectLabel] }}</span></slot>
      </template>
      <template slot="option" slot-scope="props">
        <slot :props="props">
          <template v-if="optionTooltipOnIcon && optionCustomIcon && selectLabel && !isSearching">
            <span v-b-tooltip:hover="{boundary: 'viewport', title: props.option[selectLabel]}" :class="props.option[trackBy]"><svg viewBox="0 0 24 24" class="icon-regular"><use :xlink:href="`#icon-${props.option[optionIcon]}`"></use></svg></span>
          </template>
          <template v-else>
            <svg v-if="optionCustomIcon" viewBox="0 0 24 24" class="icon-sm mr-2"><use :xlink:href="`#icon-${props.option[optionIcon]}`"></use></svg>
            <svg v-else-if="optionIcon" viewBox="0 0 24 24" class="icon-sm mr-2"><use :xlink:href="`#icon-${optionIcon}`"></use></svg>
            <slot name="optionIcon" :item="props.option"></slot>
            <span v-if="props.option.isTag">Add <strong>{{ props.option.label }}</strong></span>
            <span v-else v-b-tooltip.hover="{boundary: 'viewport', title: props.option.tooltip, disabled: !props.option.tooltip}">{{ props.option[selectLabel] }}</span>
          </template>
        </slot>
      </template>
      <template slot="afterList" slot-scope="props">
        <slot name="after" :props="props">
          <infinite-loading v-if="infinite" @infinite="$emit('infinite', $event)" :identifier="`${identifier}-${infiniteIndex}`" style="min-height: 50px">
            <div slot="spinner">
              <div class="skeleton-item dropdown-item skeleton-item-light">
                <svg width="180" height="19" viewBox="0 0 180 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.996094" width="18" height="18" rx="4" fill="#AFC3B5"/>
                  <rect x="26" y="6" width="120" height="6" rx="2" fill="#AFC3B5"/>
                </svg>
              </div>
              <div class="skeleton-item dropdown-item skeleton-item-light">
                <svg width="180" height="19" viewBox="0 0 180 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="0.996094" width="18" height="18" rx="4" fill="#AFC3B5"/>
                  <rect x="26" y="6" width="80" height="6" rx="2" fill="#AFC3B5"/>
                </svg>
              </div>
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </slot>
      </template>
      <template slot="noOptions" slot-scope="props">
        <slot name="empty" :props="props">
          <span class="txt-body" v-show="!fetching">List is empty</span>
        </slot>
      </template>
    </multiselect>
    <transition-group name="fade" appear>
      <div class="multiple-selection pt-3" v-if="showSelectedItems && multiple && model.length" key="multiple">
        <div class="fx-row-center mb-3" v-if="!hideField || showCounter">
          <p class="txt-body">{{ selectedTitle }} <strong>{{ model.length }} {{ type }}</strong></p>
          <slot name="selectedTitle"></slot>
        </div>
        <div class="btn btn-tag form-control-select-tag" :class="[{'mr-2 mb-2' : !tagBlock, 'btn-block mb-0' : tagBlock, 'tag-basic' : tagBasic, }, `btn-${tagSize}`]" v-for="item in model" :key="'item-' + item.id">
          <slot name="tagIcon" :item="item"></slot>
          <svg v-if="tagIcon" viewBox="0 0 24 24" class="btn-left icon-sm"><use :xlink:href="`#icon-${tagIcon}`"></use></svg>
          <span class="btn-tag-label" :class="tagBlock ? 'mr-auto' : ''">{{ item[selectLabel] }}</span>

          <!-- send email and impersonation buttons -->
          <slot name="actions" :item="item" v-if="tagBlock"></slot>

          <b-btn v-if="!readonly" variant="icon ml-1 primary" :size="tagSize" @click="removeItem(item)">
            <svg viewBox="0 0 24 24" class="icon-sm"><use xlink:href="#icon-cross"></use></svg>
          </b-btn>
        </div>
      </div>
      <div class="multiple-selection pt-3" v-if="suggestions.length && ($can('copilot') || $can('searchie-ai'))" key="suggestion">
        <p class="txt-body mb-2" ><strong>Suggestions</strong></p>
        <div
          v-for="item in suggestions"
          :key="'item-' + item.id"
          :class="[tagBlock ? 'btn-block' : 'mr-2 mb-2', {'disabled': isInTagModel(item)}]"
          class="btn btn-tag btn-sm"
          @click="addSuggestedTag(item)"
        >
          <svg viewBox="0 0 24 24" class="btn-left"><use :xlink:href="`#icon-${isInTagModel(item) ? 'tick' : 'plus'}`"></use></svg>
          <span class="btn-tag-label" :class="tagBlock ? 'mr-auto' : ''">{{ item.content }}</span>
        </div>
      </div>
    </transition-group>
    <b-alert variant="error" class="mt-3 mb-0" :show="!!error">
      <svg class="alert-icon icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-error-color"></use></svg>
      <div class="alert-content">
        <span class="txt-body">{{ error }}</span>
      </div>
    </b-alert>
  </div>
</template>
<script>
  import Multiselect from 'vue-multiselect';

  export default {
    name: 'FormControlSelect',
    data() {
      return {
        infiniteIndex: 0,
        infiniteStart: false
      }
    },
    props: {
      closeOnSelect: {
        type: Boolean,
        default: true
      },
      small: {
        type: Boolean,
        default: false
      },
      taggable: Boolean,
      disabled: Boolean,
      readonly: Boolean,
      multiple: Boolean,
      infinite: Boolean,
      preselectFirst: Boolean,
      full: Boolean,
      inputIcon: String,
      optionIcon: String,
      customClass: String,
      customSelectClass: String,
      optionCustomIcon: Boolean,
      optionTooltipOnIcon: Boolean,
      groupValues: {
        type: String,
      },
      groupLabel: {
        type: String,
      },
      groupSelect: Boolean,
      hideSelected: Boolean,
      priceFormatter: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      description: {
        type: String,
      },
      selectLabel: {
        type: String,
      },
      selectedTitle: String,
      trackBy: {
        type: String,
      },
      noMatchingString: {
        type: String,
        default: 'No matching options found.',
      },
      options: {
        type: Array
      },
      tagIcon: {
        type: String
      },
      tagBasic: {
        type: Boolean,
        default: false
      },
      tagSize: {
        type: String,
        default: 'sm'
      },
      type: String,
      value: {},
      blockKeys: {},
      identifier: {
        default: +new Date(),
      },
      title: {
        type: String,
        default: ''
      },
      error: {
        type: String,
      },
      tagBlock: {
        type: Boolean,
        default: false
      },
      searchVisible: {
        type: Boolean,
        default: false
      },
      hideField: {
        type: Boolean,
        default: false
      },
      showCounter: {
        type: Boolean,
        default: false
      },
      fetching: Boolean,
      isSearching: Boolean,
      tagPlaceholder: {
        type: String,
        default: 'Add tag'
      },
      suggestions: {
        type: Array,
        default: () => []
      },
      searchable: {
        type: Boolean,
        default: true
      },
      showSelectedItems: {
        type: Boolean,
        default: true
      },
      showSelectedItemsList: {
        type: Boolean,
        default: true
      },
      singleLabelIconClass: String
    },
    computed: {
      model: {
        get() {
          if (this.multiple || this.full) {
            return this.value;
          } else {
            return this.options.find(op => op && op[this.trackBy] === this.value);
          }
        },
        set(value) {
          if (!value) return;
          if(this.multiple || this.full) {
            this.$emit('input', value);
          } else {
            this.$emit('input', value[this.trackBy]);
          }
        }
      },
    },
    components: {
      Multiselect
    },
    methods: {
      removeItem(item) {
        this.$emit('input', this.model.filter(m => m.id !== item.id));
        this.$emit('remove-item', item)
      },
      open() {
        if ( !this.infiniteStart ) {
          this.infiniteIndex++;
          this.infiniteStart = true;
        }
        this.$emit('open')
      },
      close() {
        this.$emit('close')
      },
      focus() {
        if (this.$refs.multiselect) this.$refs.multiselect.toggle();
      },
      addSuggestedTag(tag) {
        if(!this.isInTagModel(tag)) {
          const payload = {
            name: tag.content
          }
          this.model = [...this.model, payload]
        }
      },
      isInTagModel(tag) {
        return this.model.find(m => m.name === tag.content)
      }
    }
  }
</script>
