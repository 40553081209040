var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.details.length
    ? _c(
        "div",
        { class: _vm.classes },
        _vm._l(_vm.details, function(detail, index) {
          var _obj
          return _c(
            "div",
            {
              key: "auth-detail-" + index,
              staticClass: "auth-page-custom-detail",
              class:
                ((_obj = {}),
                (_obj["auth-page-custom-detail-" + detail.type] = true),
                _obj)
            },
            [
              detail.type === "horizontal_rule"
                ? _c("hr")
                : detail.type === "image"
                ? _c("img", { attrs: { src: detail.src } })
                : detail.type === "video"
                ? _c("div", {
                    staticClass: "iframe-holder my-4",
                    domProps: {
                      innerHTML: _vm._s(_vm.getVideoCode(detail.hash))
                    }
                  })
                : detail.type === "heading" &&
                  (detail.level === "regular" || !detail.level)
                ? _c("h2", { staticClass: "txt-heading my-4" }, [
                    _c("strong", [_vm._v(_vm._s(detail.text))])
                  ])
                : detail.type === "heading" && detail.level === "small"
                ? _c("h3", { staticClass: "txt-heading-small" }, [
                    _c("strong", [_vm._v(_vm._s(detail.text))])
                  ])
                : detail.type === "paragraph"
                ? _c(
                    "p",
                    {
                      staticClass: "txt-heading-small",
                      class: { "txt-muted": detail.muted }
                    },
                    [_vm._v(_vm._s(detail.text))]
                  )
                : detail.type === "list"
                ? [
                    _c(
                      "ul",
                      [
                        _vm._l(_vm.getListBeforeBreak(detail.list), function(
                          item,
                          index
                        ) {
                          return _c(
                            "li",
                            { staticClass: "txt-heading-small" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "auth-page-custom-detail-list__icon mr-2"
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "16",
                                        height: "16",
                                        viewBox: "0 0 16 16",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M2.66667 8L6.66667 12.6667L13.3333 4",
                                          stroke: "#06211E",
                                          "stroke-width": "2",
                                          "stroke-miterlimit": "10",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item) +
                                  "\n                "
                              )
                            ]
                          )
                        }),
                        _vm._v(" "),
                        !_vm.hasListBreak(detail.list)
                          ? _vm._l(_vm.getListAfterBreak(detail.list), function(
                              item,
                              index
                            ) {
                              return _c(
                                "li",
                                { staticClass: "txt-heading-small" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "auth-page-custom-detail-list__icon mr-2"
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "txt-primary",
                                          attrs: {
                                            width: "24",
                                            viewBox: "0 0 24 24"
                                          }
                                        },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href": "#icon-tick"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(item) +
                                      "\n                    "
                                  )
                                ]
                              )
                            })
                          : _vm._e()
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.hasListBreak(detail.list)
                      ? _c(
                          "b-btn",
                          {
                            attrs: { variant: "link" },
                            on: {
                              click: function($event) {
                                return _vm.showListAfterBreak(detail)
                              }
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "btn-left mr-1",
                                attrs: { width: "24", viewBox: "0 0 24 24" }
                              },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#icon-chevron-down" }
                                })
                              ]
                            ),
                            _vm._v("\n                See All\n            ")
                          ]
                        )
                      : _vm._e()
                  ]
                : detail.type === "quote"
                ? _c("div", { staticClass: "my-4" }, [
                    _c("h3", { staticClass: "txt-heading-small" }, [
                      _c("strong", [_vm._v(_vm._s(detail.author))])
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "txt-heading-small txt-muted" }, [
                      _vm._v('"' + _vm._s(detail.text) + '"')
                    ])
                  ])
                : detail.type === "contact_support"
                ? _c(
                    "b-btn",
                    {
                      staticClass: "mb-4",
                      attrs: { variant: "link" },
                      on: { click: _vm.openSupportModal }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "btn-left mr-1",
                          attrs: { width: "24", viewBox: "0 0 24 24" }
                        },
                        [
                          _c("use", {
                            attrs: { "xlink:href": "#icon-user-support" }
                          })
                        ]
                      ),
                      _vm._v("\n            Contact Support\n        ")
                    ]
                  )
                : detail.type === "faq"
                ? _c("div", { staticClass: "fx-row-start" }, [
                    _c(
                      "span",
                      { staticClass: "auth-page-custom-detail-faq__icon mr-2" },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "18",
                              height: "18",
                              viewBox: "0 0 18 18",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M9 12.75V13.5M9 11.25V9.75C10.275 9.75 11.25 8.775 11.25 7.5C11.25 6.225 10.275 5.25 9 5.25C7.725 5.25 6.75 6.225 6.75 7.5M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z",
                                stroke: "#06211E",
                                "stroke-width": "2",
                                "stroke-miterlimit": "10",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("h4", { staticClass: "txt-heading-small" }, [
                        _vm._v(_vm._s(detail.question))
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "txt-heading-small txt-muted" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(detail.answer) +
                            "\n                    "
                        ),
                        detail.learnMore
                          ? _c(
                              "a",
                              {
                                staticClass: "txt-primary",
                                attrs: {
                                  href: detail.learnMore,
                                  target: "_blank"
                                }
                              },
                              [_vm._v("Learn more.")]
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              detail.type === "image"
                ? _c("div", { staticClass: "effect-right" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "52",
                          height: "49",
                          viewBox: "0 0 52 49",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M36.5363 11.7256L16.5364 36.894",
                            stroke: "black",
                            "stroke-width": "4",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M40.8585 35.1787L29.1176 43.5928",
                            stroke: "black",
                            "stroke-width": "4",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M6.07983 19.0889L5.46516 22.6633",
                            stroke: "black",
                            "stroke-width": "4",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        })
                      ]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }