import store from "@app2/store";
import get from "lodash/get";
import curatePlans from "@app2/modules/AccountSettings/Pages/Routes/Subscription/Helpers/curatePlans"
import curateAddons from "@app2/modules/AccountSettings/Pages/Routes/Subscription/Helpers/curateAddons"
import axios from "@app2/api/axios";
import router from "@app2/router";
import { set } from "@app2/utils/helpers"
import eventBus from "@app2/core/eventBus";
import { filterAddons, mappedEntitlements, getDependencies, getAddonsToExclude } from "@app2/modules/AccountSettings/Pages/Routes/Subscription/Helpers/subscription.utils";
import { hasPermission } from '@app2/utils/filters';
import capitalize from 'lodash/capitalize';

export default {
    data() {
        return {
            managePaymentUrl: null,
            addonToDelete: null,
            isDeletingAddon: false,
            downgradeErrors: [],
            downgradeErrorsDetails: [],
            downgradeErrorIsNan: false,
            isActivating: false,
        }
    },
    computed: {
        activePlan() {
            return store.getters['auth/GET_PLAN'];
        },
        trialPlan() {
            const plan = store.getters['auth/GET_PLAN'];
            const user = store.getters['auth/GET_USER'];
            if ( plan?.item_id.includes('trial') && user?.meta?.trial_hash ) {
                const planFromTrial = this.plans.find(plan => plan.hash === user.meta.trial_hash);
                if ( planFromTrial ) return planFromTrial;
            }
            return null;
        },
        plans() {
            return store.getters['auth/GET_PLANS'];
        },
        addons() {
            return store.getters['auth/GET_ADDONS'];
        },
        activeAddons() {
            return this.addons.filter(addon => addon.searchie_is_subscribed);
        },
        permissions() {
            return store.getters['auth/GET_PERMISSIONS'];
        },
        activeSubscription() {
            return store.getters['auth/GET_SUBSCRIPTION'];
        },
        activePaymentSource() {
            return store.getters['auth/GET_PAYMENT_SOURCE'];
        },
        trial() {
            return store.getters['auth/GET_TRIAL'];
        },
        activePlanName() {
            if ( this.isUsingSparkAdapter ) return this.activePlan && get(this.activePlan, 'external_name');
            return this.activePlan && get(this.activePlan, 'item.external_name');
        },
        currentPlanLabel() {
            if ( !!this.trial && this.hasPaidTrial && this.trialPlan ) return get(this.trialPlan, 'metadata.plan_information.label', this.trialPlan.external_name) + ' Plan';
            return ['Trial Subscription', 'No Subscription'].includes(this.currentPlanLabelShort) ? this.currentPlanLabelShort : this.currentPlanLabelShort + ' Plan';
        },
        isNewUserTrial() {
            return this.activePlan?.item?.external_name === 'New User Trial';
        },
        currentPlanLabelShort() {
            if ( this.trialPlan ) return get(this.trialPlan, 'metadata.plan_information.label', this.trialPlan.external_name);
            if (!!this.trial && !this.trial.is_paid) { return 'Trial Subscription'; }
            let plan = this.activePlan?.item;
            return plan && !this.isNewUserTrial ? `${get(plan, 'metadata.plan_information.label', plan.external_name)}` : 'No Subscription';
        },
        currentPlanId() {
            return this.$store.getters['auth/GET_PLAN_ID'];
        },
        activePlanId() {
            return this.activePlan && get(this.activePlan, 'item_price_id');
        },
        subscriptionIsOnGracePeriod() {
            return this.activeSubscription && this.activeSubscription.status === 'non_renewing';
        },
        subscriptionTrialIsCancelled() {
            return this.activeSubscription && this.activeSubscription.cancelled_at && this.activeSubscription.status === 'in_trial';
        },
        needsSubscription() {
            if (this.subscriptionIsOnGracePeriod) return true;
            return !this.activeSubscription || this.activeSubscription?.status === 'cancelled';
        },
        subscriptionIsCancelled() {
            return this.activeSubscription?.status === 'cancelled';
        },
        activePlanInterval() {
            let interval = this.activeSubscription && this.activeSubscription.billing_period_unit;
            if ( this.hasPaidTrial && this.trialPlan ) interval = this.trialPlan.hasOwnProperty('billing_period_unit') ? this.trialPlan.billing_period_unit : this.trialPlan.period_unit;
            return interval === 'day' ? 'month' : interval;
        },
        hasPaidTrial() {
            return this.trial && this.trial.is_paid;
        },
        subscriptionIsActive() {
            return (!this.trial || this.trial && this.trial.is_paid) && this.activeSubscription && this.activeSubscription.status === 'active';
        },
        endDate() {
            if (!!this.trial) return this.trial.trial_ends_at;
            return this.activeSubscription && this.activeSubscription.current_term_end;
        },
        curatedPlans() {
            return curatePlans(this.plans);
        },
        curatedExtraPlans() {
            return curatePlans(this.plans, { extra: true });
        },
        activePromoPlan() {
            if ( !this.activePlan?.is_plan_promo ) return;
            let plan = this.plans.find(plan => plan.item_price_id === this.activePlan.item_price_id);
            if ( !plan ) return;
            return {
                type: plan.external_name,
                plans: [plan],
                period_unit: plan.period_unit,
            }
        },
        curatedAddons() {
            let addons = filterAddons(
                {
                    plan: this.activePlanId,
                    permissions: Object.keys(this.$store.getters['auth/GET_PERMISSIONS']).filter(permission => hasPermission(permission, null, { viewOnly: true })),
                    addons: curateAddons(this.addons),
                    plans: [...this.curatedPlans, ...this.curatedExtraPlans],
                }
            );
            let first = "WisdomAI-Launch";
            if (addons.find(addon =>addon.type === first)) addons.sort(function(x,y){ return x.type == first ? -1 : y == first ? 1 : 0; });
            return addons;
        },
        curatedActiveAddons() {
            let addons = filterAddons(
                {
                    plan: this.activePlanId,
                    permissions: Object.keys(this.$store.getters['auth/GET_PERMISSIONS']).filter(permission => hasPermission(permission, null, { viewOnly: true })),
                    addons: curateAddons(this.activeAddons, true),
                    plans: [...this.curatedPlans, ...this.curatedExtraPlans],
                }
            );
            let first = "WisdomAI-Launch";
            if (addons.find(addon =>addon.type === first)) addons.sort(function(x,y){ return x.type == first ? -1 : y == first ? 1 : 0; });
            return addons;
        },
        isUsingSparkAdapter() {
            return this.activeSubscription && this.activeSubscription.spark_adapter;
        },
        activePlanPrice() {
            let priceItem = this.activeSubscription?.item_prices?.find(item => item.item_price_id === this.activePlanId);
            if ( !priceItem ) return;
            let currency = priceItem.currency_code === 'USD' ? '$' : priceItem.currency_code;
            return currency + priceItem.price / 100;
        },
        quantityPermissions() {
            return Object.keys(this.permissions).map(key => {
                let permission = this.permissions[key];
                if ( !permission.hasOwnProperty('quota') ) return;
                return {
                    name: key,
                    used: permission.used || 0,
                    quota: permission.quota || 0,
                }
            }).filter(permission => permission);
        },
        downgradeErrorText() {
            return (this.downgradeErrors.length > 1)
                ? this.downgradeErrors.slice(0, -1).join(', ') + " and " + this.downgradeErrors.slice(-1)
                : this.downgradeErrors[0];
        }
    },
    methods: {
        async cancel(data) {
            await axios.put('/api/internal/v2/chargebee/subscription/cancel', data);
            await store.dispatch('auth/fetchUser');
            await router.push({name: 'AccountSubscription'});
        },
        async resumeSubscription() {
            if ( !this.subscriptionIsOnGracePeriod ) return;
            await axios.delete('/api/internal/v2/chargebee/subscription/scheduled-cancellation');
            await this.$store.dispatch('auth/fetchUser');
            this.$toasted.show('Your subscription has been resumed.', { type: 'success' });
        },
        async reactivateSubscription() {
            if ( !this.subscriptionIsCancelled ) return;
            await axios.put('/api/internal/v2/chargebee/subscription/reactivate');
            await this.$store.dispatch('auth/fetchUser');
            this.$toasted.show('Your subscription has been reactivated.', { type: 'success' });
        },
        async activateSubscription() {
            if ( this.isActivating ) return;
            try {
                if ( this.$route.name !== 'AccountSubscription' ) {
                    await router.push({name: 'AccountSubscription'});
                }
                this.isActivating = true;
                if ( this.trialPlan ) {
                    await this.subscribeToPlan(this.trialPlan, { skipValidation: true });
                } else {
                    await axios.put('/api/internal/v2/chargebee/subscription/activate');
                    await this.$store.dispatch('auth/fetchUser');
                }
            } catch (e) {
                this.handleSubscriptionError(e);
            } finally {
                this.isActivating = false;
            }
        },
        async subscribeToPlan(plan, options = {skipValidation: false, params: {}}) {
            let priceId = typeof plan === 'string' ? plan : plan.item_price_id;
            if ( !options.skipValidation ) {
                if ( !this.validateDowngrade(priceId) ) {
                    eventBus.$emit('showDowngradeDialog', this.downgradeErrorsDetails, this.downgradeErrorIsNan);
                    return;
                }
            }
            if (priceId === this.activePlanId && !this.hasPaidTrial && !this.trialPlan) {
                if ( this.subscriptionIsOnGracePeriod && !router.currentRoute.query.rec && !router.currentRoute.query.fml ) {
                    this.resumeSubscription();
                }
                if ( this.subscriptionIsCancelled ) {
                    this.reactivateSubscription();
                }
            } else {
                const addonsToExclude = getAddonsToExclude(priceId, this.activeAddons);
                const { data } = (addonsToExclude.length)
                    ? await axios.put('/api/internal/v2/chargebee/checkout', {
                        plan_id: priceId,
                        replace: true,
                        addons: this.activeAddons.filter(addon => !addonsToExclude.includes(addon.item_price_id)).map(addon => {
                            return {
                                item_price_id: addon.item_price_id,
                                quantity: addon.pivot.quantity,
                            }
                        }),
                        ...options.params,
                    })
                    : await axios.post('/api/internal/v2/chargebee/checkout', {
                        item_price_id: priceId,
                        ...options.params,
                    });
                if ( data && data.url ) {
                    this.url = data.url;
                    this.$emit('setUrl', data.url);
                    this.$bvModal.show('checkout-modal');
                }
            }
        },
        async subscribeToCampaign(plan, options = {params: {}}) {
            const { data } = await axios.post('/api/internal/v2/campaigns/checkout', {
                hash: get(this.user, 'meta.promo_hash', this.$route.query.promo_hash),
                item_price_id: plan.item_price_id,
                no_redirect_url: true,
                // ...options.params,
            });
            if ( data && data.url ) {
                // window.open(data.url, '_self');
                this.url = data.url;
                this.$emit('setUrl', data.url);
                this.$bvModal.show('checkout-modal');
            }
            // if ( data && data.url ) {
            //     window.open(data.url, '_self');
            // }
        },
        async claimCampaign(campaign, params = {}) {
            this.$bvModal.hide('checkout-modal');
            await axios.post(`/api/internal/v2/campaigns/${campaign}/apply`, {
                params
            });
        },
        async cancelAddonSubscription() {
            if ( !this.validateAddonDowngrade(this.addonToDelete, true) ) {
                this.$bvModal.hide('addon-delete-dialog');
                this.$bvModal.show('downgrade-addon-dialog');
                return;
            }
            this.isDeletingAddon = true;
            try {
                const entitlements = mappedEntitlements[this.addonToDelete.item_id];
                const dependencies = [];
                if ( entitlements) entitlements.forEach(entitlement => {
                    dependencies.push(...getDependencies(entitlement, this.addons));
                });
                if ( dependencies.length ) {
                    await axios.put('/api/internal/v2/chargebee/team/addons/cancel/bulk', {
                        item_price_id: [
                            this.addonToDelete.item_price_id,
                            ...dependencies
                        ],
                    });
                } else {
                    await axios.put('/api/internal/v2/chargebee/team/addons/cancel', {
                        item_price_id: this.addonToDelete.item_price_id,
                    });
                }
                await this.$store.dispatch('auth/fetchAddons');
                await this.$store.dispatch('auth/fetchUser');
                this.$toasted.show('Subscription updated successfully!', { type: 'success' });
            } catch (e) {
              console.log(e);
            }
            this.isDeletingAddon = false;
            this.addonToDelete = null;
            setTimeout(() => {
                this.$bvModal.hide('addon-delete-dialog');
            }, 200)
        },
        async subscribeToAddon(addon) {
            if ( !this.validateAddonDowngrade(addon) ) {
                this.$bvModal.hide('addon-delete-dialog');
                this.$bvModal.show('downgrade-addon-dialog');
                return;
            }
            try {
                if (get(addon, 'pricing_model') === 'flat_fee') {
                    await axios.put('/api/internal/v2/chargebee/team/addons/quantity', {
                        item_price_id: addon.item_price_id,
                        quantity: 1,
                    });
                    await this.$store.dispatch('auth/fetchAddons');
                    await this.$store.dispatch('auth/fetchUser');
                    this.$toasted.show('Subscription updated successfully!', { type: 'success' });
                }
                if ((get(addon, 'pricing_model') === 'per_unit' || get(addon, 'pricing_model') === 'stairstep') && get(addon, 'pivot.quantity') > 0) {
                    await axios.put('/api/internal/v2/chargebee/team/addons/quantity', {
                        item_price_id: addon.item_price_id,
                        quantity: get(addon, 'pivot.quantity'),
                    });
                    await this.$store.dispatch('auth/fetchAddons');
                    await this.$store.dispatch('auth/fetchUser');
                    this.$toasted.show('Subscription updated successfully!', { type: 'success' });
                    }
                if ((get(addon, 'pricing_model') === 'per_unit' || get(addon, 'pricing_model') === 'stairstep') && get(addon, 'pivot.quantity') === 0) {
                    await axios.put('/api/internal/v2/chargebee/team/addons/cancel', {
                        item_price_id: addon.item_price_id,
                    });
                    await this.$store.dispatch('auth/fetchAddons');
                    await this.$store.dispatch('auth/fetchUser');
                    this.$toasted.show('Subscription updated successfully!', { type: 'success' });
                }
            } catch (e) {
                this.handleSubscriptionError(e);
            }
        },
        async openManagePaymentsModal() {
            if ( this.isUsingSparkAdapter ) return;
            const { data } = await axios.put('/api/internal/v2/chargebee/team/manage-payment-sources');
            if ( data && data.url ) {
                this.managePaymentUrl = data.url;
                eventBus.$emit('manage-payment-url', data.url);
                this.$bvModal.show('manage-payments');
            }
        },
        hideManagePaymentsModal() {
            this.$bvModal.hide('manage-payments');
        },
        hideCheckoutModal() {
            this.$bvModal.hide('checkout-modal');
        },
        handleSubscriptionError(err) {
            if (err.data.message.includes('(3001) Insufficient funds')) {
                this.$toasted.show(err.data.message, { 
                    type: 'error',
                    action: {
                        text: 'Update your payment details here.',
                        onClick: (e, toastObject) => {
                            this.openManagePaymentsModal();
                            toastObject.goAway(0);
                        }
                    }
                });
            }
        },
        getProrated(addon, qty) {
            if (qty === 0) return false
            return axios.post('/api/internal/v2/chargebee/estimate/addon', {
                item_price_id: addon.item_price_id,
                quantity: qty,
            });
        },
        getPlanFeature(plan, feature) {
            const planFeature = get(plan, 'item.features', []).find(f => f.feature_id === feature) || {
                feature_id: feature,
                pivot: {
                    value: 0,
                }
            };
            if ( planFeature.feature_id === 'hours' ) {
                planFeature.pivot.value = planFeature.pivot.value * 60 * 60;
            }
            return planFeature;
        },
        getFeatureIcon(feature) {
            if (feature === 'hubs') return 'hub';
            if (feature === 'hours') return 'clock';
            return '';
        },
        getFeatureUnit(feature) {
            if (feature === 'hubs') return 'hub';
            if (feature === 'hours') return 'hour';
            return '';
        },
        getFeatureName(feature) {
            return capitalize(feature.replace('-', ' '));
        },
        /**
         * Validates whether a downgrade of the main subscription plan is possible.
         * Checks if the provided plan can be downgraded based on quantity permissions.
         * Generates error details for any downgrade discrepancies.
         * @param {string} plan - The ID of the plan to be validated for downgrade.
         * @returns {boolean} - Returns true if the downgrade is possible; otherwise, false.
         */
        validateDowngrade(plan) {
            // Retrieve the plan details from the plans list
            plan = this.plans.find(pl => pl.item_price_id === plan);

            // Reduce the quantity permissions to validate the downgrade
            this.downgradeErrorsDetails = this.quantityPermissions.reduce((acc, key) => {
                // Retrieve feature details from the current plan and the active subscription
                let featureFromPlan = this.getPlanFeature(plan, key.name);
                let featureFromSubscription = store.getters['auth/GET_PLAN_FEATURE'](key.name);

                // Check if the usage is within the allowed limits
                if ((key.used <= featureFromPlan.pivot.value || featureFromPlan.pivot.value === 'Unlimited')) return acc;

                // If usage exceeds limits, add error details
                this.downgradeErrors.push(key.name.replace('-', ' '));
                acc.push({
                    name: this.getFeatureName(key.name),
                    difference: Math.round(key.name === 'hours' ? Math.abs((key.used - featureFromPlan.pivot.value) / 60 / 60) : Math.abs(key.used - featureFromPlan.pivot.value)),
                    icon: this.getFeatureIcon(key.name),
                    allowed: featureFromSubscription.pivot.value,
                    unit: this.getFeatureUnit(key.name),
                });
                return acc;
            }, []);

            // Return true if no downgrade errors are found
            return this.downgradeErrorsDetails.length === 0;
        },
        /**
         * Validates whether a downgrade of an addon subscription is possible.
         * Checks if the provided addon can be downgraded based on quantity permissions.
         * Generates error details for any downgrade discrepancies.
         * @param {Object} addon - The addon subscription to be validated for downgrade.
         * @param {boolean} total - Flag indicating whether to consider total count.
         * @returns {boolean} - Returns true if the downgrade is possible; otherwise, false.
         */
        validateAddonDowngrade(addon, total = false) {
            // Reduce the quantity permissions to validate the addon downgrade
            this.downgradeErrorsDetails = this.quantityPermissions.reduce((acc, key) => {
                // Find the feature associated with the addon
                const feature = addon.item.features.find(f => f.feature_id === key.name);
                if (!feature) return acc;

                // Retrieve addon details from the active subscription
                const addonFromSubscription = this.activeSubscription.item_prices.find(item => item.item_price_id === addon.item_price_id);
                const itemsPerPiece = feature?.pivot?.value || 1;
                if ( itemsPerPiece === 'Unlimited' ) return acc;
                const oldCount = parseInt(addonFromSubscription?.pivot?.quantity || 0) * parseInt(itemsPerPiece);
                const newCount = total ? 0 : parseInt(addon?.pivot?.quantity || 0) * parseInt(itemsPerPiece);
                const difference = key.name === 'hours' ? (oldCount - newCount) * 60 * 60 : oldCount - newCount;
                // Check if the downgrade is within the allowed limits
                if (key.quota - difference >= key.used || newCount > oldCount) return acc;

                // If not, add error details
                acc.push({
                    name: this.getFeatureName(key.name),
                    difference: Math.round(key.name === 'hours' ? Math.abs((key.used - difference) / 60 / 60) : Math.abs(key.used - difference)),
                    icon: this.getFeatureIcon(key.name),
                    unit: this.getFeatureUnit(key.name),
                });
                return acc;
            }, []);

            // Return true if no downgrade errors are found
            return this.downgradeErrorsDetails.length === 0;
        },
        planHasFakeTrial(planId) {
            const id = planId.split('-')[0];
            return this.activePlanId?.includes(`${id}-trial`);
        },
        async getEstimates() {
            const { data } = await axios.get('/api/internal/v2/chargebee/team/invoices/estimate-upcoming');
            return data.invoice_estimates[0];
        },
    }
}
