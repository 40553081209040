import Vue from 'vue';
import Router from '@plugins/VueRouter/index';
import auth from '@app2/modules/Auth/auth.routes';
import register from '@app2/modules/Register/register.routes';
import onboarding from '@app2/modules/Onboarding/onboarding.routes';
import audience from '@app2/modules/Audience/audience.routes';
import hub from '@app2/modules/Hubs/hubs.routes';
import dashboard from '@app2/modules/Dashboard/dashboard.routes';
import apps from '@app2/modules/Apps/apps.routes';
import library from '@app2/modules/Library/library.routes';
import account from '@app2/modules/AccountSettings/account.routes';
import store from '@app2/store';
import gettingStarted from '@app2/modules/GettingStarted/getting-started.routes'
import { addGuardstoRoutes, checkEditors, manualMultiguard } from '@app2/router/guards';
import eventBus from '@app2/core/eventBus';
import axios from '@app2/api/axios';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    ...checkEditors([
      ...auth,
      ...account,
      ...onboarding,
      ...register,
      ...addGuardstoRoutes([
        ...audience,
        ...hub,
        ...apps,
        ...library,
        ...dashboard,
        ...gettingStarted,
        { path: '/', name: 'home', redirect: { name: 'dashboard' } },
      ]),
      { path: "*", redirect: { name: 'dashboard' } }
    ]),
    {
      path: '/watch/:hash/', 
      name: 'watch',
      component: () => import('@app2/modules/Watch/Index.vue'),
      props: true
    }
  ],
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
});

router.afterEach(() => {
  store.commit('setLoadedFirstRoute', true);
  eventBus.$emit('posthog:page-view');
  setTimeout(() => {
    if ( store.state.v2.apps.isModalOpen ) return;
    window.scrollTo(0, 0);
  }, 100);
});

router.beforeEach(async (to, from, next) => {
  if ( to.name === 'AccountSubscription' && to.query.fml ) {
    const { data: {is_qualified} } = await axios.get('/api/internal/v2/fml/qualified')
    if ( is_qualified ) return next();
    await axios.put('/api/internal/v2/fml', {
      is_fml: true
    })
    await store.dispatch('auth/fetchUser');
    return next('/dashboard');
  }
  if ( to.meta.ph ) {
    eventBus.$emit('posthog:start-recording');
  } else {
    eventBus.$emit('posthog:stop-recording');
  }
  const queryParams = new URLSearchParams(to.query);
  const hasIdParam = queryParams.has('id');
  const hasStateParam = queryParams.has('state');

  if (hasIdParam && hasStateParam && to.meta.checkAndRemoveQueryParams) {
    store.commit('auth/STORE_IS_NEW_SUBSCRIPTION', true);
    queryParams.delete('id');
    queryParams.delete('state');
    next({
      path: to.path,
      query: queryParams.toString()
    })
    return;
  }

  const routeNames = ['dashboard', 'hubs', 'hub', 'hubEditorDashboard', 'audience', 'apps', 'exploreApps']; // beforeEnter alternative
  if (routeNames.includes(to.name)) {
    manualMultiguard(to, from, next);
  } else {
    next()
  }
})

export default router;
