export default (trialLength = 14) => {
    return {
        title: "Create Your Account",
        description: `You'll need a Membership.io account to participate in the challenge. Join now with a ${trialLength}-day free trial.`,
        sidebar: [
            {
                type: "heading",
                text: "Join the Founding Member Launch Challenge",
            },
            {
                type: "paragraph",
                text: `You'll need a Membership.io account to participate in the challenge. Join now with a ${trialLength}-day free trial.`
            },
            {
                type: "video",
                hash: "oNvy9JEdDX"
            },
            {
                type: "list",
                list: [
                    `${trialLength}-day free trial`,
                    "Everything you need to launch your membership",
                    "Training from Stu McLaren and the Membership.io team",
                    "Membership creators community",
                    "Scripts and templates"
                ]
            }
        ],
        step_actions: {
            details: `Start ${trialLength}-Day Free Trial`,
        },
        classes: [
            'auth-page-custom-details--fml'
        ]
    }
}