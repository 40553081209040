var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-link", {
    attrs: { to: _vm.link, custom: "" },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var navigate = ref.navigate
            return [
              _c(
                "div",
                {
                  ref: "option-menu-settings-" + _vm.optionIndex,
                  staticClass: "side-panel-option",
                  class: [
                    {
                      active: _vm.active,
                      "type-radio": _vm.radio,
                      "is-locked": _vm.isLock,
                      "px-2": _vm.radioSmall,
                      "v-centered": _vm.vCentered
                    },
                    _vm.customClass
                  ],
                  style: _vm.optionStyle,
                  on: {
                    click: function($event) {
                      return _vm.go(navigate, $event)
                    }
                  }
                },
                [
                  _c("transition", { attrs: { name: "fade", appear: "" } }, [
                    _vm.lineIndicator && _vm.active
                      ? _c("i", { staticClass: "line" })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.iconLeft || _vm.radio || _vm.checkbox
                    ? _c("div", { staticClass: "slot-left" }, [
                        _vm.iconLeft
                          ? _c(
                              "svg",
                              {
                                staticClass: "icon-regular",
                                attrs: { width: "24", viewBox: "0 0 24 24" }
                              },
                              [
                                _c("use", {
                                  attrs: {
                                    "xlink:href": "#icon-" + _vm.iconLeft
                                  }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.radio
                          ? _c("div", {
                              staticClass: "radio",
                              class: { active: _vm.active }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkbox
                          ? _c(
                              "div",
                              {
                                staticClass: "checkbox",
                                class: { active: _vm.active }
                              },
                              [
                                _c("b-form-checkbox", {
                                  model: {
                                    value: _vm.computedActive,
                                    callback: function($$v) {
                                      _vm.computedActive = $$v
                                    },
                                    expression: "computedActive"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm.image
                    ? _c("div", { staticClass: "slot-left mr-0" }, [
                        _c("figure", { staticClass: "ag-username" }, [
                          _c("div", { staticClass: "avatar" }, [
                            _c("img", { attrs: { src: _vm.image } })
                          ])
                        ])
                      ])
                    : _vm._t("left"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "inner", class: _vm.innerClass },
                    [
                      _vm.label
                        ? [
                            _c("div", { staticClass: "fx-row-center" }, [
                              _vm.radioSmall
                                ? _c(
                                    "h3",
                                    {
                                      staticClass: "txt-body",
                                      class: {
                                        "txt-primary": _vm.active,
                                        "mb-0": !_vm.description
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.label))]
                                  )
                                : _c(
                                    "h3",
                                    {
                                      staticClass: "txt-heading-small",
                                      class: {
                                        "txt-primary": _vm.active,
                                        "mb-0": !_vm.description
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.label))]
                                  ),
                              _vm._v(" "),
                              _vm.status
                                ? _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "txt-heading-small txt-bold ml-1",
                                      class: "status-" + _vm.status
                                    },
                                    [_vm._v(_vm._s(_vm.status))]
                                  )
                                : _vm._e()
                            ])
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._t("custom-label"),
                      _vm._v(" "),
                      _vm.description
                        ? _c(
                            "p",
                            {
                              staticClass: "txt-body",
                              class: _vm.isLock ? "txt-muted-2" : "txt-muted"
                            },
                            [_vm._v(_vm._s(_vm.description))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._t("custom-description"),
                      _vm._v(" "),
                      _vm._t("default")
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.iconRight && !_vm.customRight
                    ? _c(
                        "div",
                        {
                          staticClass: "slot-right",
                          class: { "txt-primary": _vm.active }
                        },
                        [
                          _vm.hasFilters
                            ? _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "7",
                                    height: "6",
                                    viewBox: "0 0 7 6",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("ellipse", {
                                    attrs: {
                                      cx: "3.46986",
                                      cy: "3.00014",
                                      rx: "2.57143",
                                      ry: "2.57143",
                                      fill: "#06211E"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.iconRight
                            ? _c(
                                "svg",
                                {
                                  attrs: { width: "24", viewBox: "0 0 24 24" }
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href": "#icon-" + _vm.iconRight
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.customRight
                    ? _c(
                        "div",
                        { staticClass: "slot-right" },
                        [_vm._t("right")],
                        2
                      )
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _vm._t("custom-cta"),
              _vm._v(" "),
              _vm.tooltip
                ? _c(
                    "b-tooltip",
                    {
                      attrs: {
                        target: function() {
                          return _vm.$refs[
                            "option-menu-settings-" + _vm.optionIndex
                          ]
                        },
                        triggers: "hover",
                        "custom-class": _vm.tooltipClass,
                        placement: "top"
                      }
                    },
                    [_vm._t("tooltip")],
                    2
                  )
                : _vm._e()
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }