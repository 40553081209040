import { get } from 'lodash'
import fml from "@app2/modules/Register/register.fml";

export default {
  data() {
    return {
      isDisabled: false
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/GET_USER']
    },
    campaign() {
      return get(store, 'state.v2.register.campaign')
    },
    isCardRequired() {
      if ( !!this.$route.query.cc ) return true;
      if ( this.checkoutPlan ) {
        if (get(this.checkoutPlan, 'metadata.checkout_information.no_cc', false)) return false;
        else if (this.checkoutPlan.price_original > 0) return true;
        else return this.checkoutPlan.price > 0;
      }
      return get(this.user, 'team.meta.latest_campaign.is_credit_card_required', get(store, 'state.v2.register.campaign.is_credit_card_required'))
    },
    isMultiplePlans() {
      return get(this.campaign, 'is_multiple_plan', false)
    },
    checkoutPlan() {
      const plan = get(this.$route, 'query.checkout', null) || get(window, 'Searchie.checkout.hash', null);
      return this.$store.getters['auth/GET_PLANS'].find(p => p.hash === plan)
    },
    trialPeriod() {
      if (this.checkoutPlan?.price === 0) return 0;
      if (this.checkoutPlan?.trial_period) return this.checkoutPlan.trial_period;
      return 0;
    },
    checkoutDetails() {
      if ( this.$route.query.fml ) return fml(this.trialPeriod);
      const checkoutVar = this.$route.query.var;
      const checkoutVarDefault = this.checkoutPlan?.metadata?.checkout_customization?.find(c => !!c.default);
      if ( !checkoutVar ) return checkoutVarDefault;
      const checkoutVarPlan = this.checkoutPlan?.metadata?.checkout_customization?.find(c => c.var === this.$route.query.var)
      return checkoutVarPlan || checkoutVarDefault;
    },
  },
}